import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BasicinterceptorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   
    const skipUrl: string | string[] = [
        'https://logisticsdev.thestorywallcafe.com/api/DriverSignup/',
        'https://logisticsdev.thestorywallcafe.com/api/user-login/'
    ]
    if (skipUrl.indexOf(request.url) === -1) {
      let value=localStorage.getItem('token');
    if(value){
      request = request.clone({
        setHeaders: {
          'Authorization':"Bearer " + `${value}`
      }
    });
    }
  }
    return next.handle(request);
  }
}
