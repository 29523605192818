import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatStepperModule} from '@angular/material/stepper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgOtpInputModule } from  'ng-otp-input';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { IonicRatingComponentModule } from 'ionic-rating-component';
import { BasicinterceptorInterceptor } from './driver-ui/services/token.interceptor';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxImageCompressService } from 'ngx-image-compress';


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    BrowserAnimationsModule,
    MatStepperModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    MatSlideToggleModule,
    IonicRatingComponentModule,

  ],
  providers: [Geolocation,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },DatePipe,NgxImageCompressService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BasicinterceptorInterceptor, multi: true
      },{provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
