import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { BehaviorSubject, Subject ,observable, Observable} from 'rxjs';

interface orderDetails{

}
interface otpverified{}
interface toggleStatus{}
interface invoice{}

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor(private imageCompress:NgxImageCompressService,) { }
  private getOrderDetails = new BehaviorSubject<orderDetails>(null); 
  private getOtpverified = new BehaviorSubject<otpverified>(null); 
  private getToggleStatus = new BehaviorSubject<toggleStatus>(null); 
  private getInvoice = new BehaviorSubject<invoice>(null); 
  private rideDetails = new Subject<any>()
  private otpDetail = new Subject<any>()
  private profile = new Subject<any>()
  private menu = new BehaviorSubject<boolean>(true);

  orderDetailsContent = this.getOrderDetails.asObservable();
  otpVerification = this.getOtpverified.asObservable();
  toggleUpdated = this.getToggleStatus.asObservable();
  invoice = this.getInvoice.asObservable();
  getRideDetails = this.rideDetails.asObservable();
  getOtpdetail = this.otpDetail.asObservable();
  getMenu = this.menu.asObservable()
  getProfile = this.profile.asObservable()

  changeProfile(data:any){
    this.profile.next(data)
  }
  hideShowMenu(data:any){
    this.menu.next(data);
  }
  sendRideDetails(data:any){
    this.rideDetails.next(data)
  }
  orderDetails(orderDetails:orderDetails){
    this.getOrderDetails.next(orderDetails);
  }
  Otpverified(otpverified:otpverified){
    this.getOtpverified.next(otpverified)
  }
  toggle(toggleStatus:toggleStatus){
    this.getToggleStatus.next(toggleStatus)
  }
  getInvoiceForRide(invoice:invoice){
    this.getInvoice.next(invoice)
  }
  sendOtpDetail(data:any){
    this.otpDetail.next(data)
  }
  finalImageContent: any;

compressFile(image: any) {
  var orientation = -1;
  return this.imageCompress.compressFile(image, orientation, 50, 50).then(result => {
    // Call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(result.split(',')[1]);

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onload = () => {
        this.finalImageContent = reader.result;
        resolve(this.finalImageContent);
      };
      reader.onerror = (error) => reject(error);
    });
  });
}

dataURItoBlob(dataURI: string) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/jpeg' });
  return blob;
}
getCompressedImageContent() {
  return this.finalImageContent;
} 
  
}
