import { Component } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from './driver-ui/services/api.service';
import { CommonService } from './driver-ui/services/common.service';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subscription !: Subscription;
  driver_id:any;
  latitude:any;
  longitude:any;
  online:boolean=false
  constructor(private geo: Geolocation, private api:ApiService,private emit:CommonService) {
    this.driver_id= Number(localStorage.getItem('driver_id'))
    
  this.emit.toggleUpdated.subscribe((resp:any)=>{
    console.log(resp,"from app");
    
    if(resp=='online'){
      this.online=true
      this.subscription = timer(0, 2000).pipe(
        switchMap(() => this.api.getOrderDetails(this.driver_id))
      ).subscribe(
        (response:any)=>{

          this.geo.watchPosition().subscribe((resp:any) => {
            if(resp && this.online==true){
              this.latitude = resp.coords.latitude
              this.longitude = resp.coords.longitude
              const data={
                "driver_id":this.driver_id ,
                "lat": this.latitude,
                "lng": this.longitude
              }
              this.api.updateDriverCurrentLocation(data).subscribe(
                (resp:any)=>{
                },
                (error:any)=>{
                  console.log(error)
                }
              )
            }
      
           }),(error) => {
            console.log('Error getting location', error);
          },{enableHighAccuracy: true};;
        },
        (error:any)=>{
          console.log(error,'getting error');
        }  
      )
    }
    else if(resp=='offline'){
      console.log('jj');
      
      this.ngOnDestroy();
      this.online=false
      
      
    }
  
  })
  }
  ngOnInit() {
    this.lockPortrait();}
 async ngOnDestroy() {
   await this.subscription.unsubscribe();
}
  // getGeoLocation(){
  //   this.geo.getCurrentPosition().then((resp) => {
  //     if(resp){
  //       this.latitude = resp.coords.latitude
  //       this.longitude = resp.coords.longitude
  //     }

  //    }).catch((error) => {
  //     console.log('Error getting location', error);
  //   }),{enableHighAccuracy: true};;
  // }
  lockPortrait() {
    ScreenOrientation.lock({
      orientation: 'portrait'
    });
  }
  }

