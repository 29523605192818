import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseurl= environment.live_url;
  token:any;
  headers = new HttpHeaders();
  constructor(private http:HttpClient) {
    this.token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization',this.token);
   }

  //driverDetails
  driverDetails(data:any){
    return this.http.post(`${this.baseurl}/DriverSignup/`,data)
  }

  
  //driverDetails
  //Edit driverDetails
  editDriverDetails(id:any,data:any){
    return this.http.put(`${this.baseurl}/updateDriverDetails/${id}`,data)
  }
  //Edit driverDetails
  //get driver details
  getDriverDetails(driver_id:any){
    return this.http.get(`${this.baseurl}/DriverSignup/?user_id=${driver_id}`)
  }
  //get driver details
    //Truck icon
    getTotalEarning(id:any,date:any){
      return this.http.get(`${this.baseurl}/drivertotal_earning/?year=${date}&driver_id=${id}`)
    }
    //Truck icon
  //vehicleDetails
  vehicleDetails(data:any){
    return this.http.post(`${this.baseurl}/vehicle/`,data)
  }
  //vehicleDetails
  //EDit vehicleDetails
  editVehicleDetails(data:any){
    return this.http.put(`${this.baseurl}/vehicle/`,data)
  }
  //EDit vehicleDetails
  //update online status
  updateOnlineStatus(data:any){
    return this.http.post(`${this.baseurl}/update-driver-online-status/`,data,)
  }
  //update online status
  //get order details
  getOrderDetails(driver_id:any){
    return this.http.get(`${this.baseurl}/driver-order-detail?driver_id=${driver_id}`)
  }
  //get order details
  //get Accepted order details
  getAcceptedOrderDetails(driver_id:any,order_id:any){
    return this.http.get(`${this.baseurl}/drivers-order-detail?driver_id=${driver_id}&order_id=${order_id}`)
  }
  //get order details
  //update order details
  updateOrderDetails(data:any){
    return this.http.post(`${this.baseurl}/driver-order-detail`,data)
  }
  //update order details
  //feedback
  feedBack(data:any){
    return this.http.post(`${this.baseurl}/user-feedback`,data) 
  }
  //feedback
  //Trip deatils
  getTripDetails(driver_id:any){
    return this.http.get(`${this.baseurl}/driver-order-detail?driver_id=${driver_id}`) 
  }
  //Trip deatils
  // About us
  getAboutUs(){
    return this.http.get(`${this.baseurl}/Aboutus/`) 
  }
  // About us
  // help
  getHelp(){
    return this.http.get(`${this.baseurl}/queries/`) 
  }
  // help

  //Driver Current location update
  updateDriverCurrentLocation(data:any){
    return this.http.put(`${this.baseurl}/update-driver-location`,data) 
  }
  //Driver Current location update
  //driver rating
  getDriverRating(data:any){
    return this.http.get(`${this.baseurl}/driver-overall-ratings/?driver_id=${data}`)
  }
  //driver rating
  //file size
  restrictFileSize(){
    return this.http.get(`${this.baseurl}/filesize/`)
  }
  //file size
   //get Invoice
   getInvoice(data:any){
    return this.http.post(`${this.baseurl}/recipt-details`, data) 
  }
  //get Invoice

  // Document Expiry
  getDocumentExpiry(data:any){
    return this.http.get(`${this.baseurl}/notify-driver-document-expiry?driver_id=${data}`)
  }
  // Document Expiry
  //Truck icon
  getTruckIcon(data:any){
    return this.http.get(`${this.baseurl}/vehicle-types?id=${data}`)
  }
  //Truck icon
  //Send Otp to user
  otp(data:any){
    return this.http.post(`${this.baseurl}/send-otp-to-sub-user`, data)
  }
  //Send Otp to user
  getOtpDetails(id:any){
    return this.http.get(`${this.baseurl}/get-otp-details?order_id=${id}`)
  }
  //get year
  getDriverYear(driver_id:any){
    return this.http.get(`${this.baseurl}/driveryear/?driver_id=${driver_id}`)
  }
  //get year
  getMessage(driver_id:any){
    return this.http.get(`${this.baseurl}/sendmessage/?driver_id=${driver_id}`)
  }

  subscription(){
    return this.http.get(`${this.baseurl}/subscription/`)
  }
  //get final amount
  getFinalAmount (data:any){
    return this.http.post(`${this.baseurl}/total-amount-after-completeion-of-ride`, data)
  }

  sendSubcriptionDetails(data:any){
    return this.http.post(`${this.baseurl}/vehicle_subscription/`, data)
  }
  sendPaymentDetails(data:any){
    return this.http.put(`${this.baseurl}/vehicle_subscription/`,data)
  }
  getVehicleSubscriptionDetails(id:any){
    return this.http.get(`${this.baseurl}/vehicle_subscription/?vehicle_id=${id}`)
  }
  getFurureRides(id:any){
    return this.http.get(`${this.baseurl}/all-scheduled-orders?driver_id=${id}`)
  }
  acceptFutureRide(data:any){
    return this.http.post(`${this.baseurl}/all-scheduled-orders`, data)
  }

  calculateETA(){
    return this.http.get(`${this.baseurl}/calculate-eta/`)
  }
}
